<template>
  <div class="add-stock">
    <KTCodePreview v-bind:title="'Thông tin phiếu nhập kho'">
      <template v-slot:preview>
        <b-row
          class="col-md-12 mb-1 m-0 p-0"
          v-if="purchaseOrderId"
        >
          <div
            class="alert-header"
            style="width: 100%"
          >
            Tạo phiếu nhập từ phiếu yêu cầu nhập hàng:
            <span
              class="text-primary orderCode"
              @click="linkToPO(poCode)"
              style="cursor: pointer"
            >{{ poCode }}</span>
          </div>
        </b-row>
        <b-row class="mb-5">
          <!-- INFO INPUT CONTROLS -->
          <b-col
            lg="6"
            md="6"
            sm="12"
          >
            <b-row>
              <b-col
                lg="6"
                md="6"
                sm="12"
              >
                <b-form-group class="required-control">
                  <label>Ngày nhập kho:</label>
                  <date-picker
                    disabled
                    placeholder="Chọn ngày nhập kho"
                    class="mb-2 mt-2 form-control form-control-sm"
                    :config="dpConfigs.date"
                    v-model="$v.form.dateImport.$model"
                    :state="validateState('dateImport')"
                    aria-describedby="input-date-feedback"
                  ></date-picker>

                  <b-form-invalid-feedback v-if="!$v.form.dateImport.required">Vui lòng chọn ngày nhập kho</b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col
                lg="6"
                md="6"
                sm="12"
              >
                <b-form-group>
                  <template>
                    <span>Tổng tiền:</span>
                  </template>
                  <p class="mt-2">{{ convertPrice(totalPrice) }}</p>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col
                lg="6"
                md="6"
                sm="12"
              >
                <b-form-group class="required-control">
                  <label>Kho:</label>
                  <Autosuggest
                    class="border-radius-none mt-2"
                    :model="searchStore"
                    :suggestions="filteredOptions"
                    placeholder="Kho"
                    :limit="200"
                    @select="onSelectedStore"
                    @change="onInputChange"
                    suggestionName="suggestionName"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group class="required-control">
                  <label>Nhà cung cấp:</label>
                  <b-input-group>
                    <Autosuggest
                      class="border-radius-none mt-2"
                      :model="searchProvider"
                      :suggestions="filteredOptionsProvider"
                      placeholder="nhà cung cấp"
                      :limit="200"
                      @select="onSelectedProvider"
                      @change="onInputChangeProvider"
                      suggestionName="suggestionName"
                    />
                    <b-input-group-append>
                      <span
                        @click="showOriginalModel = !showOriginalModel"
                        class="input-group-text sub-add"
                      >
                        <i class="fas fa-plus"></i>
                      </span>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col
                lg="12"
                md="12"
                sm="12"
              >
                <b-form-group>
                  <label>Ghi chú:</label>
                  <b-form-textarea
                    size="sm"
                    v-model="notes"
                    :placeholder="'Thêm nội dung ghi chú...'"
                    :rows="6"
                    :max-rows="6"
                  ></b-form-textarea>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <!-- INFO VAT INPUT CONTROLS -->
          <b-col
            lg="3"
            md="3"
            sm="12"
          >
            <b-form-group>
              <label>Chiết khấu:</label>
              <b-row>
                <b-col
                  class="pr-0"
                  cols="5"
                >
                  <b-form-select
                    class="select-style"
                    v-model="selectDiscountType"
                    :options="listAmountType"
                    size="sm"
                    value-field="id"
                    text-field="name"
                  >
                  </b-form-select>
                </b-col>
                <b-col
                  class="pl-0"
                  cols="7"
                >
                  <b-form-input
                    class="input-style text-right"
                    size="sm"
                    v-model="discountAmount"
                    v-mask="formater.currency"
                    placeholder="Chiết khấu"
                  ></b-form-input>
                </b-col>
              </b-row>
            </b-form-group>

            <b-form-group>
              <b-checkbox v-model="haveVAT">Có hóa đơn VAT</b-checkbox>
              <b-row
                style="margin-top: 0.75rem"
                v-if="haveVAT"
              >
                <b-col
                  class="pr-0"
                  cols="5"
                >
                  <b-form-select
                    class="select-style"
                    v-model="selectVatType"
                    :options="listAmountType"
                    size="sm"
                    value-field="id"
                    text-field="name"
                    disabled-field="notEnabled"
                  >
                  </b-form-select>
                </b-col>
                <b-col
                  class="pl-0"
                  cols="7"
                >
                  <b-form-input
                    class="input-style text-right"
                    size="sm"
                    type="text"
                    v-model="vatAmount"
                    v-mask="formater.currency"
                    placeholder="VAT"
                  ></b-form-input>
                </b-col>
              </b-row>
            </b-form-group>

            <b-form-group v-if="haveVAT">
              <label>Số hóa đơn VAT:</label>
              <b-form-input
                class="input-style"
                size="sm"
                type="text"
                v-model="vatCode"
                placeholder="Số hóa đơn VAT"
              ></b-form-input>
            </b-form-group>
            <b-form-group v-if="haveVAT">
              <label>Ngày xuất VAT:</label>
              <date-picker
                placeholder="Chọn ngày xuất VAT"
                class="form-control form-control-sm"
                :config="dpConfigs.date"
                v-model="vatDate"
              ></date-picker>
            </b-form-group>
          </b-col>
          <!-- PAYMENT INPUT CONTROLS -->
          <b-col
            lg="3"
            md="3"
            sm="12"
          >
            <b-row>
              <b-col
                lg="12"
                md="12"
                sm="12"
              >
                <b-form-group>
                  <label for="input-name">Tiền mặt</label>
                  <b-form-input
                    class="input-style text-right"
                    size="sm"
                    type="text"
                    v-model="cashAmount"
                    placeholder="Nhập số tiền mặt"
                    v-mask="formater.currency"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                lg="12"
                md="12"
                sm="12"
              >
                <b-form-group :label="'Tài khoản tiền mặt'">
                  <Autosuggest
                    class="border-radius-none"
                    :model="searchCash"
                    :suggestions="filteredOptionsCash"
                    placeholder="tài khoản tiền mặt"
                    :limit="200"
                    @select="onSelectedCash"
                    @change="onInputChangeCash"
                    suggestionName="suggestionName"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col
                lg="12"
                md="12"
                sm="12"
              >
                <b-form-group>
                  <label for="input-name">Tiền chuyển khoản</label>
                  <b-form-input
                    class="input-style text-right"
                    size="sm"
                    type="text"
                    v-model="transferAmount"
                    placeholder="Nhập số tiền"
                    v-mask="formater.currency"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                lg="12"
                md="12"
                sm="12"
              >
                <b-form-group
                  :label="'Tài khoản chuyển khoản'"
                  label-for="select-accountant"
                >
                  <Autosuggest
                    class="border-radius-none mt-2"
                    :model="searchTransfer"
                    :suggestions="filteredOptionsTransfer"
                    placeholder="tài khoản chuyển khoản"
                    :limit="200"
                    @select="onSelectedTransfer"
                    @change="onInputChangeTransfer"
                    suggestionName="suggestionName"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                lg="12"
                md="12"
                sm="12"
              >
                <b-form-group :label="'Ngày thanh toán'">
                  <date-picker
                    placeholder="Chọn ngày thanh toán"
                    class="mb-2 mt-2 form-control form-control-sm"
                    :config="dpConfigs.date"
                    v-model="payDate"
                  ></date-picker>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="mb-4">
          <b-col
            lg="2"
            md="2"
            sm="12"
            class="pr-0"
          >
            <b-form-select
              class="select-style"
              v-model="selectTypeSearch"
              :options="listTypeSearch"
              size="sm"
              value-field="id"
              text-field="name"
              disabled-field="notEnabled"
            ></b-form-select>
          </b-col>
          <b-col
            lg="7"
            md="7"
            sm="12"
            class="pl-0"
          >
            <Autosuggest
              :model="isDisabled ? searchProduct : ''"
              :suggestions="filteredOptionsProduct"
              placeholder="tên, mã, mã vạch sản phẩm"
              :limit="10"
              @select="onSelectedProduct"
              @change="onInputChangProduct"
              :disabled="isDisabled"
              suggestionName="productName"
            />
          </b-col>
          <b-col md="3">
            <div class="d-flex justify-content-end">
              <b-button
                variant="secondary"
                size="sm"
                class="font-weight-bolder"
                @click="showModalImportProduct"
                :disabled="!inventorySelected"
              >
                <i
                  style="font-size: 1rem"
                  class="far fa-file-excel"
                ></i>Nhập SP
                từ excel
              </b-button>
            </div>
          </b-col>
        </b-row>
        <b-row class="mb-4">
          <b-col md="9">
            <span class="font-weight-bolder">Danh sách sản phẩm:</span>
          </b-col>
        </b-row>
        <table class="table table-bordered table-vertical-center table-hover col-12 mt-4">
          <thead>
            <tr>
              <th
                scope="col"
                class="title-center"
                style="width: 15%"
              >
                Tên sản phẩm
              </th>
              <th
                scope="col"
                class="title-center"
                style="width: 5%"
              >Tồn</th>
              <th
                scope="col"
                class="title-center"
                style="width: 20%"
              >IMEI</th>
              <th
                scope="col"
                class="title-center"
                style="width: 10%"
              >
                Số lượng
              </th>
              <th
                scope="col"
                class="title-center"
                style="width: 18%"
              >
                Đơn giá
              </th>
              <th
                scope="col"
                class="title-center"
                style="width: 14%"
              >
                Thành tiền
              </th>
              <th
                scope="col"
                class="title-center"
                style="width: 13%"
              >
                Chiết khấu
              </th>
              <th
                scope="col"
                class="title-center"
                style="width: 5%"
              ></th>
            </tr>
          </thead>

          <tbody
            v-for="item in listProductStock"
            :key="item.id"
          >
            <ImportStock
              ref="import_stock"
              v-bind:productItem="item"
              v-on:cancelAdd="cancelAdd"
              v-on:save="save"
              v-on:deleteItem="deleteItemInArray"
              v-on:update="updateItem"
              v-bind:editState="true"
              v-bind:editPrice="purchaseOrderId !== null"
            />
          </tbody>
          <tr>
            <td
              :colspan="3"
              class="tdTextAlignPrice"
            >Tổng</td>
            <td class="tdTextAlignPrice">{{ convertPrice(totalQuantity) }}</td>
            <td class="tdTextAlignPrice">
              {{ convertPrice(totalUnitPrice) }}
            </td>
            <td class="tdTextAlignPrice">{{ convertPrice(totalPrice) }}</td>
            <td class="tdTextAlignPrice">
              {{ convertPrice(totalDiscount) }}
            </td>
            <td></td>
          </tr>
        </table>

        <div
          class="col-md-12"
          style="padding-left: 0px"
        >
          <div class="mb-5 mt-5">
            <b-button
              v-show="listProductStock.length > 0"
              v-bind:style="btnCreate"
              size="sm"
              variant="primary"
              class="font-weight-bolder"
              @click="onSubmit"
            >
              Lưu
            </b-button>
          </div>
          <b-modal
            v-model="showOriginalModel"
            hide-footer
            title="Thêm mới nhà cung cấp"
          >
            <b-row class="mb-5">
              <b-col>
                <label class="labelInput">Tên nhà cung cấp:</label>
                <b-form-input
                  v-model="providerName"
                  size="sm"
                  placeholder="Nhập tên nhà cung cấp"
                ></b-form-input>
              </b-col>
              <b-col>
                <label class="labelInput">Số điện thoại nhà cung cấp:</label>
                <b-form-input
                  v-model="providerPhone"
                  size="sm"
                  placeholder="Nhập số điện thoại nhà cung cấp"
                ></b-form-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-button
                  style="fontweight: 600; width: 70px"
                  variant="primary"
                  size="sm"
                  @click="createProvider()"
                >Lưu</b-button>
                <b-button
                  @click="showOriginalModel = !showOriginalModel"
                  style="margin-left: 10px; font-weight: 600; width: 70px"
                  variant="secondary"
                  size="sm"
                >Hủy</b-button>
              </b-col>
            </b-row>
          </b-modal>
          <ImportExcelStockSlip
            ref="stock-slip-excel"
            :type="1"
            :storeId="inventorySelected"
            v-on:validData="validData"
          />
          <WarningImportStockSlip
            ref="warning-po-modal"
            v-on:onContinute="submitForm"
          />
        </div>
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import moment from 'moment';
import ApiService from '@/core/services/api.service';
import ImportStock from '@/view/components/ImportStock';
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import { cmdUrl } from '@/utils/apiUrl';
import _ from 'lodash';
import {
  currencyMask,
  unMaskPrice,
  convertPrice,
  isValidMinQuantity,
} from '@/utils/common';
import { TIME_TRIGGER } from '@/utils/constants';
import { PRODUCT_TYPE, AMOUNT_TYPE } from '@/utils/enum';
import decounce from 'debounce';
import ImportExcelStockSlip from '@/view/components/stock-slips/ImportExcelStockSlip';
import WarningImportStockSlip from '@/view/components/stock-slips/WarningImportStockSlip';
import { v4 } from 'uuid';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import { getToastInstance } from '@/utils/toastHelper';
import { makeToastFaile, makeToastSuccess } from '@/utils/common';

export default {
  mixins: [validationMixin],
  data() {
    return {
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      btnCreate: {
        fontWeight: '600!important',
        'margin-right': '-10px',
        width: '70px',
      },
      form: {
        providerSelected: null,
        dateImport: '',
      },
      isNew: true,
      dateCreate: '',
      listInventories: [],
      listProviders: [],
      listProduct: [],
      listProductSearch: [],
      notes: '',
      filteredOptionsProduct: [],
      optionsProduct: [
        {
          data: [],
        },
      ],
      searchProduct: 'Tìm kiếm theo tên, mã, mã vạch sản phẩm',
      listTypeSearch: [
        {
          id: 1,
          name: 'Tìm sản phẩm',
        },
      ],
      isDisabled: true,
      selectTypeSearch: 1,
      listProductStock: [],
      selectedClassProduct: null,
      filteredOptions: [],
      options: [
        {
          data: [],
        },
      ],
      searchStore: '',
      inventorySelected: null,
      cashAmount: 0,
      cashAccountCode: '',
      transferAmount: 0,
      transferAccountCode: '',
      payDate: null,
      filterModel: {
        cashAccountants: [],
        transferAccountants: [],
      },
      formater: {
        currency: currencyMask,
      },
      showOriginalModel: false,
      providerName: '',
      providerPhone: '',
      filteredOptionsProvider: [],
      optionsProvider: [
        {
          data: [],
        },
      ],
      searchProvider: '',
      filteredOptionsCash: [],
      optionsCash: [
        {
          data: [],
        },
      ],
      searchCash: '',
      filteredOptionsTransfer: [],
      optionsTransfer: [
        {
          data: [],
        },
      ],
      searchTransfer: '',
      isSearching: false,
      selectDiscountType: 1,
      listAmountType: [
        {
          id: 1,
          name: 'Tiền mặt',
        },
        {
          id: 2,
          name: '%',
        },
      ],
      discountAmount: 0,
      selectVatType: 2,
      vatAmount: 0,
      vatCode: '',
      vatDate: '',
      purchaseOrderId: null,
      poCode: '',
      haveVAT: false,
      purchaseOrderDetails: [],
    };
  },
  validations: {
    form: {
      providerSelected: { required },
      dateImport: { required },
    },
    searchStore: { required },
  },
  components: {
    KTCodePreview,
    ImportStock,
    Autosuggest,
    ImportExcelStockSlip,
    WarningImportStockSlip,
  },
  created() {
    this.fetchStoreByUser();
    this.fetchProvider();
    this.fetchAccountants('transfer');
  },
  mounted() {
    this.dateCreate = moment().format('HH:mm DD/MM/YYYY');
    this.$v.form.dateImport.$model = moment().format('DD/MM/YYYY');
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Phiếu nhập kho', route: '/stocks' },
      { title: 'Tạo phiếu nhập kho' },
    ]);
    if (this.$route.query.purchaseOrderId) {
      this.purchaseOrderId = this.$route.query.purchaseOrderId;
      this.getInfoById();
    }
  },
  computed: {
    totalPrice() {
      let total = 0;
      for (let index = 0; index < this.listProductStock.length; index++) {
        const element = this.listProductStock[index];
        total +=
          unMaskPrice(element.price) * element.quantity -
          unMaskPrice(element.discount);
      }

      const discountValue = this.discountAmount
        ? unMaskPrice(this.discountAmount)
        : 0;
      let discountAmount = discountValue;
      if (this.selectDiscountType === AMOUNT_TYPE.PERCENT) {
        discountAmount = (discountValue * total) / 100;
      }

      total = total - discountAmount;
      const vatValue = this.vatAmount ? unMaskPrice(this.vatAmount) : 0;

      let vatAmount = vatValue;
      if (this.selectVatType === AMOUNT_TYPE.PERCENT) {
        vatAmount = (vatValue * total) / 100;
      }

      total = parseInt(total) + parseInt(vatAmount);
      return total;
    },
    totalDiscount() {
      let total = 0;
      for (let index = 0; index < this.listProductStock.length; index++) {
        const element = this.listProductStock[index];
        total += unMaskPrice(element.discount) * 1;
      }
      return total;
    },
    totalQuantity() {
      let total = 0;
      for (let index = 0; index < this.listProductStock.length; index++) {
        const element = this.listProductStock[index];
        total += element.quantity * 1;
      }
      return total;
    },
    totalUnitPrice() {
      let total = 0;
      for (let index = 0; index < this.listProductStock.length; index++) {
        const element = this.listProductStock[index];
        total += unMaskPrice(element.price) * 1;
      }
      return total;
    },
  },
  methods: {
    convertPrice,
    ...getToastInstance(),
    validateState(name) {
      if (!this.isNew) {
        const { $dirty, $error } = this.$v.form[name];
        return $dirty ? !$error : null;
      }
    },
    getPOProductMap() {
      if (this.purchaseOrderDetails && this.purchaseOrderDetails.length) {
        return this.purchaseOrderDetails.reduce((acc, curr) => {
          if (!acc[curr.productId]) {
            acc[curr.productId] = curr.price;
          }
          return acc;
        }, {});
      }
      return {};
    },
    onSelectedProduct(option) {
      this.selectedClassProduct = option.item;
      this.searchProduct = option.item.productName;
      let quantity = 0;
      const poProductMap = this.getPOProductMap();
      if (
        this.selectedClassProduct.productType === PRODUCT_TYPE.PRODUCT ||
        this.selectedClassProduct.productType === PRODUCT_TYPE.PRODUCT_COMPONENT
      ) {
        quantity = 1;
        const index = this.listProductStock.findIndex((item) => {
          return item.proId === this.selectedClassProduct.productId;
        });

        if (index === -1) {
          let price = this.selectedClassProduct.originalPrice;
          if (poProductMap[this.selectedClassProduct.productId]) {
            price = poProductMap[this.selectedClassProduct.productId];
          }

          const pro = {
            id: v4(),
            name: this.selectedClassProduct.productName,
            productCode: this.selectedClassProduct.productCode,
            barCode: this.selectedClassProduct.barCode,
            totalQuantityInStock:
              this.selectedClassProduct.quantityInStock != null
                ? this.selectedClassProduct.quantityInStock
                : 0,
            IMEI: '',
            proId: this.selectedClassProduct.productId,
            price: price,
            quantity: quantity,
            discount: 0,
            totalPrice: quantity * price,
            productType: this.selectedClassProduct.productType,
          };
          this.listProductStock.unshift(pro);
        } else {
          this.listProductStock[index].quantity =
            this.listProductStock[index].quantity + quantity;
        }
      } else if (this.selectedClassProduct.productType === 2) {
        let price = this.selectedClassProduct.originalPrice;

        if (poProductMap[this.selectedClassProduct.productId]) {
          price = poProductMap[this.selectedClassProduct.productId];
        }

        const pro = {
          id: v4(),
          name: this.selectedClassProduct.productName,
          productCode: this.selectedClassProduct.productCode,
          barCode: this.selectedClassProduct.barCode,
          totalQuantityInStock:
            this.selectedClassProduct.quantityInStock != null
              ? this.selectedClassProduct.quantityInStock
              : 0,
          IMEI: '',
          proId: this.selectedClassProduct.productId,
          price: price,
          quantity: quantity,
          discount: 0,
          totalPrice: quantity * price,
          productType: this.selectedClassProduct.productType,
        };
        this.listProductStock.unshift(pro);
      } else if (this.selectedClassProduct.productType === 3) {
        makeToastFaile('Không hỗ trợ nhập kho sản phẩm combo');
      }

      this.searchProduct = '';
    },
    onInputChangProduct(text) {
      if (!text) {
        text = '';
      }
      this.searchProduct = text;
      this.debounceInputProduct();
    },
    onSubmit: async function () {
      if (!this.$v.form.providerSelected.$model) {
        makeToastFaile('Vui lòng chọn nhà cung cấp!');
        return;
      }

      let isMinQuantity = true;
      isMinQuantity = isValidMinQuantity(this.$refs.import_stock);

      if (!isMinQuantity) {
        makeToastFaile('Vui lòng nhập số lượng lớn hơn bằng 1');
        return;
      }

      if (this.poCode) {
        const isValidPO = this.validatePO();
        if (!isValidPO) {
          return;
        }
      }

      this.submitForm();
    },
    cancelAdd() {
      this.listProduct.shift();
    },
    save(item) {
      this.listProduct[0] = item;
    },
    fetchStore() {
      ApiService.get(`stores?limit=10000&page=1`).then(({ data }) => {
        this.listInventories = data.data.list_store;
        this.listInventories.map((element) => {
          let store = {
            id: element.id,
            name: element.name,
            shortName: element.shortName,
            suggestionName: element.shortName + ' ( ' + element.name + ')',
          };
          this.options[0].data.push(store);
        });
        let tmp = [{ data: this.options[0].data }];
        this.filteredOptions = tmp;
      });
    },
    fetchAccountants: async function (mode) {
      this.optionsCash[0].data = [];
      let params = {
        type: mode === 'cash' ? '1' : '2',
      };
      let url = cmdUrl.AccountantUrl.byType;
      if (this.inventorySelected !== null && mode === 'cash') {
        url = 'accountants/getByStore';
        params = {
          type: mode === 'cash' ? '1' : '2',
          storeId: this.inventorySelected,
        };
      }
      ApiService.query(url, {
        params,
      }).then((response) => {
        const data = response.data.data || [];
        _.map(data, (item) => {
          let element = {
            code: item.code,
            name: item.name,
            suggestionName: `(${item.code}) - ${item.name}`,
          };
          if (mode === 'cash') {
            this.optionsCash[0].data.push(element);
          } else if (mode === 'transfer') {
            this.optionsTransfer[0].data.push(element);
          }
        });
        this.filteredOptionsCash = [...this.optionsCash[0].data];
        this.filteredOptionsTransfer = [...this.optionsTransfer[0].data];
      });
    },
    fetchProvider() {
      ApiService.get(`providers/getAll`).then(({ data }) => {
        this.listProviders = data.data.providers;
        this.optionsProvider[0].data = this.listProviders.map((element) => {
          return {
            id: element.id,
            name: element.name,
            suggestionName: element.name,
            numPaymentDate: element.numPaymentDate,
          };
        });
        this.filteredOptionsProvider = [...this.optionsProvider[0].data];
      });
    },
    fetchProduct(textSearch) {
      this.isSearching = true;
      ApiService.get(
        `productSearch/withMerchant?storeId=${this.inventorySelected}&searchProduct=${textSearch}`,
      ).then(({ data }) => {
        this.optionsProduct[0].data = [];
        const products = data.data;
        products.map((element) => {
          this.optionsProduct[0].data.push(element);
        });
        this.filteredOptionsProduct = [...this.optionsProduct[0].data];
        this.isSearching = false;
        if (textSearch !== this.searchProduct) {
          let nextSearchProduct = this.searchProduct;
          this.fetchProduct(nextSearchProduct);
        }
      });
    },
    deleteItemInArray(id) {
      for (var i = 0; i < this.listProductStock.length; i++) {
        if (this.listProductStock[i].id === id) {
          this.listProductStock.splice(i, 1);
        }
      }
    },
    updateItem(item) {
      this.listProductStock.forEach((element, index) => {
        if (element.id === item.id) {
          this.listProductStock[index].name = item.name;
          this.listProductStock[index].IMEI = item.IMEI;
          this.listProductStock[index].proId = item.proId;
          this.listProductStock[index].price = unMaskPrice(item.price);
          this.listProductStock[index].quantity = parseInt(item.quantity);
          this.listProductStock[index].discount = item.discount
            ? unMaskPrice(item.discount)
            : 0;
          this.listProductStock[index].totalPrice = item.totalPrice;
        }
      });
    },
    fetchProductImei() {
      this.listProduct = [];
      ApiService.get(`stocks/get-product-by-store-imei`).then(({ data }) => {
        this.optionsProduct[0].data = [];
        const products = data.data;
        this.listProduct = products;
        products.map((element) => {
          this.optionsProduct[0].data.push(element);
        });
      });
    },
    onChangeStore() {
      this.listProductStock = [];
      this.fetchAccountants('cash');
    },
    onSelectedStore(option) {
      this.searchStore = option.item.name;
      this.inventorySelected = option.item.id;
      this.isDisabled = false;
      this.onChangeStore();
    },
    onInputChange(text) {
      this.searchStore = text;
      const filteredData = this.options[0].data
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.shortName.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);
      this.filteredOptions = [...filteredData];
    },
    onSelectedProvider(option) {
      this.searchProvider = option.item.name;
      this.$v.form.providerSelected.$model = option.item.id;
      const numDate = option.item.numPaymentDate;
      if (numDate > 0) {
        this.payDate = moment().add(numDate, 'days').format('DD/MM/YYYY');
      }
      if (!numDate) {
        this.payDate = '';
      }
    },
    onInputChangeProvider(text) {
      this.searchProvider = text;
      const filteredData = this.optionsProvider[0].data
        .filter((item) => {
          return item.name.toLowerCase().indexOf(text.toLowerCase()) > -1;
        })
        .slice(0, this.limit);
      this.filteredOptionsProvider = [...filteredData];
    },
    onSelectedCash(option) {
      this.searchCash = option.item.name;
      this.cashAccountCode = option.item.code;
    },
    onInputChangeCash(text) {
      this.searchCash = text;
      const filteredData = this.optionsCash[0].data
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.code.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);
      this.filteredOptionsCash = [...filteredData];
    },
    onSelectedTransfer(option) {
      this.searchTransfer = option.item.name;
      this.transferAccountCode = option.item.code;
    },
    onInputChangeTransfer(text) {
      this.searchTransfer = text;
      const filteredData = this.optionsTransfer[0].data
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.code.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);
      this.filteredOptionsTransfer = [...filteredData];
    },
    createProvider: async function () {
      if (!this.providerName) {
        return;
      }

      const data = {
        name: this.providerName,
        phoneNo: this.providerPhone,
        status: 1,
      };

      ApiService.post('providers', data).then((response) => {
        const { status, message } = response.data.data;
        if (status === 1) {
          this.fetchProvider();
          this.showOriginalModel = !this.showOriginalModel;
          makeToastSuccess(message);
        } else {
          this.showOriginalModel = !this.showOriginalModel;
          makeToastFaile(message);
        }
      });
    },
    searchProductAPI() {
      if (!this.isSearching) {
        let textSearch = this.searchProduct;
        if (this.selectTypeSearch === 2) {
          this.fetchProductImei();
        } else {
          this.fetchProduct(textSearch);
        }
      }
    },
    debounceInputProduct: decounce(function () {
      this.searchProductAPI();
    }, TIME_TRIGGER),
    fetchStoreByUser() {
      ApiService.get(`stores/get-by-user`).then(({ data }) => {
        if (data.status === 1) {
          const stores = data.data.stores;
          if (stores.length === 1) {
            this.inventorySelected = stores[0].id;
            this.searchStore = stores[0].name;
            this.isDisabled = false;
          } else {
            this.options[0].data = [];
            this.options[0].data = stores.map((element) => {
              return {
                id: element.id,
                name: element.name,
                shortName: element.shortName,
                suggestionName: element.shortName + ' ( ' + element.name + ')',
              };
            });
            this.filteredOptions = [...this.options[0].data];
          }
          this.fetchAccountants('cash');
        }
      });
    },
    showModalImportProduct() {
      this.$refs['stock-slip-excel'].showModal();
    },
    validData(data) {
      if (this.poCode) {
        this.listProductStock = [];
      }
      data.forEach((element) => {
        const item = {
          id: element.id,
          name: element.productName,
          productCode: element.productCode,
          barCode: element.barCode,
          totalQuantityInStock: element.totalQuantityInStock,
          IMEI: element.imeiCode,
          proId: element.productId,
          price: element.unitPrice,
          quantity: element.quantity,
          discount: element.discountAmount,
          totalPrice: element.totalAmount,
          productType: element.productType,
        };
        this.listProductStock.push(item);
      });
    },
    getInfoById() {
      ApiService.get(`purchase-order/${this.purchaseOrderId}`)
        .then(({ data }) => {
          this.poCode = data.data.code ? data.data.code : '';
          this.notes = data.data.description ? data.data.description : '';
          this.searchStore = data.data.storeName ? data.data.storeName : '';
          this.inventorySelected = data.data.storeId;
          this.searchProvider = data.data.providerName
            ? data.data.providerName
            : '';
          this.$v.form.providerSelected.$model = data.data.providerId;
          this.discountAmount = data.data.discountAmount
            ? data.data.discountAmount
            : 0;
          this.vatAmount = data.data.vatAmount ? data.data.vatAmount : 0;
          this.selectDiscountType = data.data.discountType
            ? data.data.discountType
            : 1;
          this.selectVatType = data.data.vatType ? data.data.vatType : 2;
          this.payDate = data.data.payDate
            ? moment(data.data.payDate).format('DD-MM-YYYY')
            : '';
          this.vatDate = data.data.vatDate
            ? moment(data.data.vatDate).format('DD-MM-YYYY')
            : '';
          this.searchCash = data.data.cashAccountName
            ? data.data.cashAccountName
            : '';
          this.searchTransfer = data.data.transferAccountName
            ? data.data.transferAccountName
            : '';
          this.vatCode = data.data.vatCode ? data.data.vatCode : '';
          this.listProductStock = data.data.details.map((element) => {
            const totalAmount =
              element.quantity * element.unitPrice - element.discountAmount;
            return {
              totalAmount: totalAmount,
              id: element.id,
              name: element.productName,
              IMEI: '',
              proId: element.productId,
              price: element.unitPrice,
              quantity: element.quantityApprove,
              totalPrice: totalAmount,
              productType: element.productType,
              totalQuantityInStock: element.inStockQuantity,
              discount: element.discountAmount ? element.discountAmount : 0,
              barCode: element.barCode ? element.barCode : '',
              productCode: element.productCode ? element.productCode : '',
              productId: element.productId,
            };
          });
          this.purchaseOrderDetails = _.cloneDeep(this.listProductStock);
          this.fetchAccountants('cash');
        })
        .catch((response) => {
          makeToastFaile(response);
        });
    },
    linkToPO: function (poCode) {
      const { href } = this.$router.resolve({
        name: 'info-purchase-orders',
        query: { id: poCode },
      });
      window.open(href, '_blank');
    },
    groupProductQuantityById: function (details) {
      const listResult = [];
      const groupProduct = _.groupBy(details, 'proId');
      const keys = Object.keys(groupProduct);
      keys.map((key) => {
        const productItems = groupProduct[key];
        const quantity = _.sumBy(productItems, 'quantity');

        const result = {
          ...productItems[0],
          proId: key,
          quantity: quantity,
        };
        listResult.push(result);
      });

      return listResult;
    },
    showModalWarningImport(warningItems) {
      this.$refs['warning-po-modal'].showModal(warningItems);
    },
    validatePO() {
      const groupPurchaseOrderDetails = this.groupProductQuantityById(
        this.purchaseOrderDetails,
      );
      const groupStockSlipDetails = this.groupProductQuantityById(
        this.listProductStock,
      );

      const warningItems = [];
      groupStockSlipDetails.map((stockSlipDetail) => {
        const poItem = groupPurchaseOrderDetails.find(
          (poDetail) => poDetail.proId === stockSlipDetail.proId,
        );
        const poQuantity = poItem ? parseInt(poItem.quantity) : 0;
        if (poQuantity !== stockSlipDetail.quantity) {
          const warningItem = {
            productName: stockSlipDetail.name,
            poQuantity: poQuantity,
            stockSlipQuantity: stockSlipDetail.quantity,
          };
          warningItems.push(warningItem);
        }
      });
      if (warningItems.length) {
        this.showModalWarningImport(warningItems);
        return false;
      }

      return true;
    },
    submitForm() {
      const listPro = this.listProductStock.map((element) => {
        element.IMEI = element.IMEI.trim();
        return {
          productId: element.proId,
          imeis: element.IMEI.replace(/[\n\r]/g, ', '),
          quantity: element.quantity,
          unitPrice: element.price ? unMaskPrice(element.price) : 0,
          discount: element.discount ? unMaskPrice(element.discount) : 0,
        };
      });
      const data = {
        storeId: this.inventorySelected,
        providerId: this.$v.form.providerSelected.$model,
        description: this.notes,
        importDate: this.$v.form.dateImport.$model
          ? moment(this.$v.form.dateImport.$model, 'DD/MM/YYYY').format(
              'yyyy-MM-DD',
            )
          : '',
        listProduct: listPro,
        cashAmount: this.cashAmount ? unMaskPrice(this.cashAmount) : 0,
        cashAccountCode: this.cashAccountCode,
        transferAmount: this.transferAmount
          ? unMaskPrice(this.transferAmount)
          : 0,
        transferAccountCode: this.transferAccountCode,
        payDate: this.payDate
          ? moment(this.payDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : null,
        vatDate: this.vatDate
          ? moment(this.vatDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : null,
        vatType: this.selectVatType,
        vatAmount: this.vatAmount ? unMaskPrice(this.vatAmount) : 0,
        vatCode: this.vatCode,
        discountType: this.selectDiscountType,
        discountAmount: this.discountAmount
          ? unMaskPrice(this.discountAmount)
          : 0,
        purchaseOrderId: this.purchaseOrderId,
        purchaseOrderCode: this.poCode,
      };
      if (this.isNew === true) {
        this.isNew = false;
        ApiService.post('stock-slips/import-stock-slip', data)
          .then(({ data }) => {
            if (data.status === 1) {
              makeToastSuccess(data.message);
              setTimeout(() => {
                this.$router.push({
                  name: 'update-stock',
                  query: { id: data.data },
                });
              }, TIME_TRIGGER);
            } else {
              this.isNew = true;
              makeToastFaile(data.message);
            }
          })
          .catch(({ response }) => {
            this.isNew = true;
            makeToastFaile(response.$error);
          });
      }
    },
  },
};
</script>

<style lang="scss">
.add-stock {
  .title-center {
    text-align: center;
  }

  .input-group-append {
    cursor: pointer;
    padding-top: 5.5px;
  }

  .input-group-append:hover i {
    color: #3699ff;
  }

  .tdTextAlignPrice {
    text-align: right !important;
    vertical-align: middle;
    font-weight: 600;
  }
  .alert-header {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
  }
  .orderCode:hover {
    text-decoration: underline;
  }
}
</style>
